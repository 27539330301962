// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);
document.addEventListener('scroll', setVHSize);

// Navtoggler Funcionality

document.querySelector('.header__navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('activenav');
})

// Offcanvas Navigation Functionality
for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth < 1280) {
      e.preventDefault();
      parentMenuItem.classList.toggle('clicked');
    }
  })
}

let openInitialActiveOffcanvasItem = () => {
  if (window.innerWidth < 1280) {
    for (let activeParentMenuItem of document.querySelectorAll('.header__navigation-mainnav li.parent.active')) {
      activeParentMenuItem.classList.add('clicked');
    }
  }
}

document.addEventListener('DOMContentLoaded', openInitialActiveOffcanvasItem)

// Footernav Functionality
for (let parentMenuItem of document.querySelectorAll('.footer__main-navigation li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      if (e.target.tagName === 'A') {
        if (!parentMenuItem.classList.contains('clicked')) {
          e.preventDefault();
          parentMenuItem.classList.add('clicked');
        }
      } else {
        e.preventDefault();
        parentMenuItem.classList.toggle('clicked');
      }
    }
  })
}

// Offcanvas Cart Toggle
if(document.querySelector('.header__offcanvascarttoggler')) {
  document.querySelector('.header__offcanvascarttoggler').addEventListener('click', () => {
    document.body.classList.toggle('activeoffcanvascart');
  })
  document.querySelector('.offcanvascart__header-closer').addEventListener('click', () => {
    document.body.classList.toggle('activeoffcanvascart');
  })
}


// Show Offcanvas cart when model is selected or removed

for (let addModelToSelectionButton of document.querySelectorAll('button')) {
  addModelToSelectionButton.addEventListener('click', () => {
    if (addModelToSelectionButton.dataset.action == 'add') {
      console.log('Test');
      document.body.classList.add('activeoffcanvascart');
      setTimeout(function () {
        document.body.classList.remove('activeoffcanvascart')
      },2500)
    }
  })
}
// Sidebar-Navi
document.addEventListener('DOMContentLoaded', () => {
  for (let parentSidebarnavItem of document.querySelectorAll('.nav--sidebarnav li.parent, .nav--frontendsidebarnav li.parent')) {
    parentSidebarnavItem.querySelector('a, span').addEventListener('click', (e) => {
      if(!parentSidebarnavItem.classList.contains('clicked')) {
        e.preventDefault()
        parentSidebarnavItem.classList.add('clicked');
      }
    })
  }
})

// Add Pageclass on Scroll

window.addEventListener('scroll', () => {
  if (window.scrollY > 100) {
    document.body.classList.add('scrolled');
  } else {
    document.body.classList.remove('scrolled');
  }
})

// AOS - Initialize AOS Library

window.addEventListener('DOMContentLoaded', () => {
  let initializeAOS = () => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out-quart',
      once: true,
      anchorPlacement: 'top-bottom'
    });
  }
  window.setTimeout(initializeAOS,5);
});

